import React from 'react';
import { ResynthesisConfig } from '../types';

interface ResynthesisSettingsProps {
  config: ResynthesisConfig;
  onChange: (config: ResynthesisConfig) => void;
}

export default function ResynthesisSettings({ config, onChange }: ResynthesisSettingsProps) {
  const handleChange = (field: keyof ResynthesisConfig, value: number | boolean) => {
    onChange({
      ...config,
      [field]: value,
    });
  };

  return (
    <div className="space-y-4 p-4 bg-gray-50 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Advanced Settings</h3>

      <div className="space-y-2">
        <label className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-700">Minimum Segment Length (seconds)</span>
          <input
            type="number"
            min={1}
            max={20}
            step={0.5}
            value={config.min_segment_length}
            onChange={(e) => handleChange('min_segment_length', parseFloat(e.target.value))}
            className="w-24 px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={config.use_single_sample}
            onChange={(e) => handleChange('use_single_sample', e.target.checked)}
            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
          />
          <span className="text-sm font-medium text-gray-700">Use Single Sample</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={config.use_dead_silence}
            onChange={(e) => handleChange('use_dead_silence', e.target.checked)}
            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
          />
          <span className="text-sm font-medium text-gray-700">Use Dead Silence</span>
        </label>

        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={config.use_synthesized_silence}
            onChange={(e) => handleChange('use_synthesized_silence', e.target.checked)}
            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-primary"
          />
          <span className="text-sm font-medium text-gray-700">Use Synthesized Silence</span>
        </label>

        <label className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-700">Blend Length (seconds)</span>
          <input
            type="number"
            min={0.01}
            max={1.0}
            step={0.01}
            value={config.blend_len_s}
            onChange={(e) => handleChange('blend_len_s', parseFloat(e.target.value))}
            className="w-24 px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </label>

      </div>
    </div>
  );
}